.sectionMenu {
    background-color: rgb(242, 97, 63);
    border-radius: 20px;

    margin-top: 10px;

    display: flex;

    transition:
            height 0.5s 2.5s,
            width 0.5s 2s,
            background-color .5s,
            transform .5s,
            opacity .5s
;

    overflow: hidden;

    pointer-events: all;
    cursor: pointer;

    font-size: 1rem;
}

.sectionMenu:hover {
    background-color: white;
}

.textContainer {
    position: absolute;

    display: flex;
    flex-direction: column-reverse;
    white-space: nowrap;

    color: black;

    transition: transform 0.5s 2.5s;
}

.item {
    width: 200px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
}
