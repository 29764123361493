.container {
    width: 40px;
    height: 40px;

    display: grid;
    justify-items: center;
    align-items: center;

    pointer-events: all;
    cursor: pointer;
}

.container > div {
    grid-column: 1;
    grid-row: 1;
}

.tablet {
    width: 50%;
    height: 8%;

    border-radius: 2.5px;

    transition: transform .25s, background-color 0s .125s;
}

.tablet1 {
    composes: tablet;
}

.tablet2 {
    composes: tablet;
}
