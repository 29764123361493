.mainContainer {
  position: fixed;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(12, 12, 12);
}

.sceneContainer {
  width: 100%;
  height: 100%;
}
