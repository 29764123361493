.modalContainer {
    position: absolute;

    height: 100svh;
    width: 30vw;
    max-width: 600px;

    right: 0;
    top: 0;

    background-color: rgb(12, 12, 12);
    color: white;

    pointer-events: all;

    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    user-select: text;
}

.modalContainer::-webkit-scrollbar {
    display: none;
}

.contentContainer {
    position: relative;

    width: 100%;

    padding: 40px;

    display: flex;
    flex-direction: column;

    transition: transform 2.5s 0.5s;
}

@media (max-aspect-ratio: 1/1) {
    .contentContainer {
        padding: 20px;
        padding-bottom: 40px;
    }
}

.titleContainer {
    display: grid;

    grid-template-rows: min-content;
    grid-template-columns: 1fr min-content;

    width: 100%;
    max-width: 100%;

    margin-bottom: 1rem;
}

.title {
    font-size: 2rem;
    font-weight: 500;
    color: rgb(242, 97, 63);
}

.contentContainer p {
    text-align: justify;
}

.contentContainer p > span {
    color: rgb(242, 97, 63);
}

.contentContainer p:nth-of-type(odd) {
    color: rgb(220, 220, 220);
}

.contentContainer p:nth-of-type(even) {
    color: rgb(180, 180, 180);
}

.elbowDecoration {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.elbowDecoration > div {
    position: absolute;
    right: 0;
    bottom: 0;

    background-color: rgb(242, 97, 63);
    width: 20px;
    height: 4px;

    border-radius: 2px;
}

.elbowDecoration > div:first-of-type {
    transform-origin: bottom right;
    transform: rotate(90deg) translateY(4px)
}

.imageGrid {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.imageContainer {
    width: 100%;
    padding: 20px;

    border-radius: 10px;

    filter: invert(.8)
}

.imageContainer > img {
    width: 100%;
}
