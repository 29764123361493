.modalContainer {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translateX(-50%) translateY(-50%);

    width: 100%;
    height: 100%;

    background-color: black;

    transition: opacity 0.5s,
                background-color 0.5s;
}

.sectionsContainer {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translateX(-50%) translateY(-50%);

    display: flex;
    flex-direction: column;

    align-items: center;
}

.sectionName {
    font-size: 2rem;
    color: rgb(180, 180, 180);
    white-space: nowrap;

    cursor: pointer;

    transition: color 0.2s;
}

.sectionName:nth-child(1) {
    transition: color 0.2s, opacity 0.2s 0.1s, transform 0.2s 0.1s;
}

.sectionName:nth-child(2) {
    transition: color 0.2s, opacity 0.2s 0.2s, transform 0.2s 0.1s;
}

.sectionName:nth-child(3) {
    transition: color 0.2s, opacity 0.2s 0.3s, transform 0.2s 0.1s;
}

.sectionName:nth-child(4) {
    transition: color 0.2s, opacity 0.2s 0.4s, transform 0.2s 0.1s;
}

.sectionName:hover {
    color: rgb(242, 97, 63);
}

.exitBtn {
    width: 50px;
    height: 50px;

    display: grid;
    justify-items: center;
    align-items: center;

    pointer-events: all;
    cursor: pointer;

    transition: color 0.2s, opacity 1s 0.5s, transform 0.2s 0.1s;
}

.exitBtn > div {
    grid-column: 1;
    grid-row: 1;
}

.exitBtn:hover > .tablet {
    background-color: rgb(242, 97, 63);
}

.tablet {
    width: 50%;
    height: 8%;

    background-color: rgb(180, 180, 180);

    border-radius: 2.5px;

    transition: transform .25s, background-color 0.2s;
}

.tablet1 {
    composes: tablet;

    transform: rotate(-45deg);
}

.tablet2 {
    composes: tablet;

    transform: rotate(45deg);
}

.languageChangeContainer {
    position: absolute;
    bottom: 5svh;
    left: 50%;
    transform: translateX(-50%);

    color: rgb(180, 180, 180);

    pointer-events: all;

    display: flex;
    gap: 20px;
}

.languageChangeContainer > .imageContainer {
    cursor: pointer;
    width: 30px;

}

.languageChangeContainer > .imageContainer > img {

    width: 100%;

    filter: saturate(0.5);
}

