@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');*/

:root {
  --window-shadow: rgba(0, 0, 0, 0.16) 0px 1px 1px, rgb(51, 51, 51) 0px 0px 0px 1px, rgba(0, 0, 0, 0.24) 0px 3px 4px 5px;
}

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

img {
  pointer-events: none;
  user-select: none;
}

html, body {
  overflow: hidden;
  overscroll-behavior: none;
}

html {
  -webkit-tap-highlight-color: transparent;

  -ms-touch-action: none;
  touch-action: none;

  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #f0f0f0;
}
