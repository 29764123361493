.topBar {
    width: 100%;

    background: linear-gradient(180deg, rgba(0,0,0,1) 25%, rgba(0,0,0,0) 100%);

    color: white;

    pointer-events: all;
}

.gridContainer {

    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr;

    align-items: start;
    justify-items: center;
}

@media (max-aspect-ratio: 1/1) {
    .gridContainer {
        grid-template-columns: 1fr 2fr 1fr;
    }
}

.navItem {
    position: relative;
    margin-top: 2rem;

    transition: opacity 1s, color .5s;

    cursor: pointer;

    isolation: isolate;

    text-align: center;
}

.navItem:hover {
    color: black !important;
}

.navItem::before {
    content: '';

    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    width: calc(100% + 1.5rem);
    height: calc(100% + 1rem);

    border-radius: 30px;

    background-color: rgb(242, 97, 63);
    opacity: 0;

    transition: opacity .3s;
}

.navItem:hover::before {
    opacity: 1;
}

.titleContainer {
    margin-top: 20px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 1rem;
}

@media (max-aspect-ratio: 1/1) {
    .titleContainer {
        font-size: 3.5vw;
    }
}

.title {
    color: white;
    font-size: 3em;
    margin-bottom: 0;

    white-space: nowrap;
}

.subtitle {
    color: rgb(180, 180, 180);
    font-size: 1.125em;
    font-weight: 500;
    margin-top: -0.5em;

    white-space: nowrap;
}

.menuIcon {

    margin-top: 2rem;

    width: 1.5rem;

    display: flex;
    flex-direction: column;
    gap: 4px;
}

.menuIcon > div {
    width: 100%;
    height: 4px;
    background-color: rgb(242, 97, 63);
    border-radius: 5px;
}

.menuIcon:active > div {
    background-color: rgb(255, 255, 255);
}
