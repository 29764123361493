.mainContainer {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    overflow-y: auto;
    overscroll-behavior-y: contain;
    scroll-snap-type: y mandatory;

    ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.mainContainer::-webkit-scrollbar {
    display: none;
}

.section {
    height: 100svh;
    width: 100%;

    scroll-snap-align: center;
    scroll-snap-stop: always;

}
