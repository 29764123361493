.container {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    width: 30vw;

    color: rgb(180, 180, 180);
    text-align: justify;
    text-align-last: center;
}

@media (max-aspect-ratio: 1/1) {
    .container {
        width: 60vw;
    }
}
