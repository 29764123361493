.hud {
    position: fixed;

    width: 100vw;
    height: 100svh;

    left: 50%;
    top: 50%;
}

.footer {
    color: rgba(255, 255, 255, 0.6);
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);

    font-size: .8rem;

    white-space: nowrap;
}


