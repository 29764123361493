.wrapper {
    display: grid;
    place-content: center;

    text-transform: uppercase;

    white-space: nowrap;
}

.wrapper > div {
    grid-area: 1/1/-1/-1;
}
.top {
    clip-path: polygon(0% 0%, 100% 0%, 100% 41%, 0% 58%);
}
.bottom {
    clip-path: polygon(0% 65%, 100% 48%, 100% 100%, 0% 100%);
    color: transparent;
    /*background: -webkit-linear-gradient(177deg, black 53%, white 65%);*/
    background: linear-gradient(177deg, black 43%, white 63%);
    background-clip: text;
    -webkit-background-clip: text;
    transform: translateX(-0.02em);
}
